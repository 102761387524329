import React, { useEffect, useState } from 'react'
import searchSectionStyles from './search.section.module.scss'
import { useInput } from '../../hooks/input.hook'
import { navigate } from 'gatsby'
import { useScroll } from '../../hooks/scroll.hook'
import Select from '../select/select.component'

const selectData = [
   { value: 'older', name: 'Oldest to Newest' },
   { value: 'newer', name: 'Chronological' },
]

export default function SearchSection({ onChange, value, categories }) {
   const { value: search, bind: bindSearch } = useInput('')
   //const { value: sortOrder, bind: bindSortOrder } = useInput("");
   const [sortOrder, setSortOrder] = useState()
   const { value: sortMedium, bind: bindSortMedium } = useInput('')
   const { scrollTop } = useScroll()
   const [medium, setMedium] = useState('')

   //useEffect(() => {
   //navigate(`/esteban-vicente/artwork?search=${search}`);
   //}, [search]);

   useEffect(() => {
      onChange && onChange(search, sortOrder, sortMedium, medium)
   }, [search, sortOrder, sortMedium, medium])

   const handleClick = data => {
      //console.log("click val", data);
      setSortOrder(data.value)
   }

   const handleSubmit = evt => {
      evt.preventDefault()
      //navigate(`/esteban-vicente/artwork?search=${search}`)
      onChange && onChange(search, sortOrder, sortMedium, medium)
   }

   const handleMedium = medium => {
      setMedium(medium)
      onChange && onChange(search, sortOrder, sortMedium, medium)
      //console.log('medium', medium)
   }

   return (
      <form
         className={`${searchSectionStyles.container} ${
            scrollTop > 200 ? searchSectionStyles.small : ''
         }`}
         onSubmit={handleSubmit}
      >
         <div className={searchSectionStyles.wrapper}>
            <div className={`${searchSectionStyles.select}`}>
               <Select
                  label='Sort by Date'
                  showLabel={false}
                  data={selectData}
                  name='sort-by'
                  onChange={handleClick}
                  defaultValue={1}
                  //required={required.country}
               />
            </div>

            <div className={searchSectionStyles.column}>
               <input
                  name='search'
                  type='search'
                  autoComplete='off'
                  value={search ? search : null}
                  {...bindSearch}
                  placeholder={value ? value : 'Search Artwork'}
               />
            </div>

            <div
               className={searchSectionStyles.right}
               style={{ visibility: 'hidden', opacity: 0 }}
            >
               <select id='sort-medium' name='sort-medium' {...bindSortMedium}>
                  <option value='' disabled>
                     Sort by: Medium
                  </option>
                  <option value=''>All</option>
                  {categories.map(element => {
                     const name = element.node.name
                     return (
                        <option
                           key={element.node.id}
                           value={name.toLowerCase()}
                        >
                           {name}
                        </option>
                     )
                  })}
               </select>
            </div>
         </div>
         <div className={searchSectionStyles.results}>
            <h5>Results:</h5>
            <ul>
               <li>
                  <button
                     className={medium === '' ? searchSectionStyles.active : ''}
                     onClick={() => handleMedium('')}
                     title='All media'
                  >
                     All Media
                  </button>
               </li>
               {categories.map(element => {
                  const name = element.node.name
                  return (
                     <li key={element.node.id}>
                        <button
                           className={
                              medium === name.toLowerCase()
                                 ? searchSectionStyles.active
                                 : ''
                           }
                           title={name}
                           onClick={() => handleMedium(name.toLowerCase())}
                        >
                           {name}
                        </button>
                     </li>
                  )
               })}
            </ul>
         </div>
      </form>
   )
}
