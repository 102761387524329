import React, { useEffect, useRef, useState } from 'react'
import imageStyles from './image.module.scss'

export default function Image_({ src, background = true, ...otherProps }) {
   const [isLoaded, setIsLoaded] = useState(false)
   const wrapper = useRef(null)

   useEffect(() => {
      !isLoaded && loadedImage()
      wrapper.current.style.opacity = 1
   }, [isLoaded])

   const loadedImage = () => {
      setTimeout(() => {
         setIsLoaded(true)
      }, 700)
   }

   return (
      <div
         ref={wrapper}
         style={{
            backgroundImage: background ? `url(${src?.base64})` : 'none',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            opacity: 0,
            transition: '.5s ease-in',
         }}
         {...otherProps}
      >
         {src?.src ? (
            <picture className={imageStyles.pic}>
               <img
                  alt={otherProps.alt}
                  loading='lazy'
                  className={isLoaded ? imageStyles.show : imageStyles.hidden}
                  src={src?.src}
                  onLoad={loadedImage}
                  width={background ? otherProps.width : 'auto'}
                  //{...otherProps}
               />
            </picture>
         ) : (
            'No image'
         )}
      </div>
   )
}
