import React, { forwardRef, useRef, useState } from 'react'
import selectStyles from './select.module.scss'
//import useRules from './rules.hook'

const exampleData = [
   { value: 'value1', name: 'Name 1' },
   { value: 'value2', name: 'Name 2' },
]

const capitalize = s => {
   if (typeof s !== 'string') return ''
   return s.charAt(0).toUpperCase() + s.slice(1)
}

const Select = forwardRef(
   (
      {
         name = 'default',
         label = 'label',
         showLabel = true,
         defaultValue,
         showLine,
         onChange,
         showRequiredLine,
         data = [...exampleData],
         required,
      },
      ref
   ) => {
      const [isOpen, setIsOpen] = useState(false)
      const [dValue, setDValue] = useState(defaultValue)
      const [value, setValue] = useState({
         name: null,
         value: null,
         selected: null,
      })

      //const [errors, values, handleValidate] = useRules(value)
      const optionsRef = useRef(null)

      const handleSelect = (name, value, selected) => {
         setValue({ name, value, selected })
         setDValue(false)

         //console.log("validate", Validate({ name }), errors);
         //handleValidate({ name, value, selected })
         //console.log("ERRORS", errors);

         onChange && onChange({ name, value, select: selected })
      }

      const handleToggle = () => {
         setIsOpen(!isOpen)
      }

      //if (name === 'dunit') name = 'unit of measure'

      //console.log("errors", errors, "values", values, "selected", value.selected);

      return (
         <div
            className={`${selectStyles.container} ${
               isOpen ? selectStyles.isOpened : ''
            }`}
            ref={ref}
            onClick={handleToggle}
         >
            <div
               className={`${selectStyles.header} ${
                  isOpen ? selectStyles.isOpen : ''
               }`}
            >
               {!isOpen
                  ? showLabel && <label htmlFor={name}>{label}</label>
                  : ''}

               <div
                  className={`${selectStyles.select} ${
                     !showLine || isOpen ? selectStyles.noLine : ''
                  } ${showRequiredLine ? selectStyles.required : ''}`}
                  name={name}
                  required={showRequiredLine}
                  //{...otherProps}
               >
                  {!showLabel
                     ? !isOpen
                        ? value.name || label
                        : label
                     : isOpen
                     ? !value.name
                        ? `Select a ${label}`
                        : `Selected ${capitalize(name)}: ${value.name}`
                     : value.name || `Not selected`}
               </div>
            </div>

            <div
               ref={optionsRef}
               className={`${selectStyles.options} ${
                  isOpen ? selectStyles.isOpen : ''
               } ${data.length > 4 ? selectStyles.scroll : ''}`}
            >
               {data.map((val, key) => (
                  <div
                     key={key}
                     className={`${selectStyles.option} ${
                        val.name === value.name || dValue === key
                           ? selectStyles.isSelected
                           : ''
                     }`}
                     onClick={() => {
                        //console.log('////////////////// val', val)
                        handleSelect(
                           val.name,
                           val?.val || val?.value,
                           name.toLowerCase()
                        )
                     }}
                  >
                     {' '}
                     {val.name}
                  </div>
               ))}
            </div>
         </div>
      )
   }
)

export default Select
