import { useState } from 'react'

export const useInput = initialValue => {
  const [value, setValue] = useState(initialValue)
  const [name, setName] = useState('')

  return {
    value,
    setValue,
    name,
    setName,
    reset: () => setValue(''),
    bind: {
      value,
      onChange: event => {
        setValue(event.target.value)
        setName(event.target.name)
      },
    },
  }
}
