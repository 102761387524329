import React from 'react'
import { Link } from 'gatsby'
//import Pagination from "./pagination.js"
import { InfiniteScroll } from '../infiniteScroll/infiniteScroll.module'
//import { FaCog } from "react-icons/fa"
//import theme from "../theme.yaml"
//import Grid from "./grid.js"
//import TrafficLight from "./trafficlight.js"

/** View for "home" page with infinite scroll and fallback to pagination. */
class View extends React.Component {
   //static contextType = ThemeContext
   constructor(props) {
      super(props)
      console.log('*** Constructing View ***', props)
      if (
         props.themeContext.isInitializing() ||
         !props.themeContext.useInfiniteScroll
      ) {
         const pageKey = 'page' + props.pageContext.currentPage
         console.log(
            `Result View is initializing items according to ${pageKey}.`
         )
         props.themeContext.updateState({
            data: {
               ...props.pageContext.data,
               [pageKey]: props.pageContext.pageImages,
            },
            data2: [...props.pageContext.pageImages],
            cursor: props.pageContext.currentPage + 1,
         })
      }
   }

   render() {
      const g = this.props.themeContext
      const pageContext = this.props.pageContext
      const paginationData = {
         currentPage: pageContext.currentPage,
         countPages: pageContext.numPages,
         useInfiniteScroll: g.useInfiniteScroll,
      }
      console.log('+++++ paginationData', paginationData)
      console.log('+++++ themeContext', g)

      return (
         <>
            <InfiniteScroll
               throttle={150}
               threshold={1800}
               hasMore={g.hasMore(pageContext)}
               onLoadMore={g.loadMore}
            >
               {/*console.log('pageContext', g, pageContext)*/}
            </InfiniteScroll>
            {
               //<ul>
               //<li>Current page: {paginationData.currentPage}</li>
               //<li>Page counts: {paginationData.countPages}</li>
               //</ul>
            }
            <style jsx>
               {`
                  @keyframes spinner {
                     to {
                        transform: rotate(360deg);
                     }
                  }
                  .spinner {
                     margin-top: 40px;
                     font-size: 60px;
                     text-align: center;
                     :global(svg) {
                        fill: ${'red'};
                        animation: spinner 3s linear infinite;
                     }
                  }
               `}
            </style>
         </>
      )
   }
}

export default View
