import React, { useState, useEffect, useContext, useCallback } from 'react'
import Layout from '../../components/layout/layout.component'
import Container from '../../components/container/container.component'
import SearchSection from '../../components/searchSection/search.section.component'
import { graphql, Link, navigate } from 'gatsby'
import exhibitionsPageStyles from './artworks.module.scss'
import Img from 'gatsby-image'
import ThemeContext from '../../context/ThemeContext'
import { useSize } from '../../hooks/size.hook'
import View from '../../components/view/view.module'
import Image from '../../components/image/image.component'

export const query = graphql`
   query($limit: Int!, $skip: Int!) {
      search: allNodeArtwork(limit: $limit, skip: $skip) {
         edges {
            node {
               field_object_number
               field_date
               field_medium
               title
               relationships {
                  imgDescktop: field_image {
                     localFile {
                        childImageSharp {
                           fixed(height: 180, quality: 15) {
                              ...GatsbyImageSharpFixed
                           }
                        }
                     }
                  }
                  imgMobile: field_image {
                     localFile {
                        childImageSharp {
                           fixed(width: 120, quality: 15) {
                              ...GatsbyImageSharpFixed
                           }
                        }
                     }
                  }
                  field_category {
                     name
                  }
               }
            }
         }
      }

      categories: allTaxonomyTermCategory(sort: { order: ASC, fields: name }) {
         edges {
            node {
               id
               name
            }
         }
      }

      allMenuItems(
         filter: {
            childrenMenuItems: { elemMatch: { title: { ne: null } } }
            url: { eq: "/esteban-vicente" }
         }
      ) {
         nodes {
            id
            title
            url
            weight
            childrenMenuItems {
               weight
               url
               title
            }
         }
      }
   }
`
export default function ExhibitionsPage({ data, pageContext }) {
   console.log('------------- pageContext', pageContext)
   //
   const [search, setSearch] = useState({
      typing: false,
      text: '',
      order: 'newer',
      medium: '',
   })

   const context = useContext(ThemeContext)
   const { isMobile } = useSize()
   let timeout = null

   //const edges = data.search.edges || []
   const edges = context.data2 || []
   console.log('----------- context', context)
   const [isFiltering, setIsFiltering] = useState(false)
   const [hasMore, setMore] = useState(edges.length > 30)
   const [currentList, addToList] = useState([...edges.slice(0, 30)])

   useEffect(() => {
      window.addEventListener('scroll', handleScroll)
      return () => {
         window.removeEventListener('scroll', handleScroll)
      }
   }, [hasMore, currentList])

   const handleScroll = () => {
      const wrapper = document.querySelector(`.${exhibitionsPageStyles.list}`)
      if (!hasMore) return
      if (wrapper.scrollHeight + wrapper.scrollTop === wrapper.offsetHeight)
         loadEdges(true)
   }

   const loadEdges = () => {
      const currentLength = currentList.length
      const more = currentLength < edges.length
      const nextEdges = more
         ? edges.slice(currentLength, currentLength + 20)
         : []
      setMore(more)
      addToList([...currentList, ...nextEdges])
   }

   const doSearch = data => {
      // clear all timeout
      clearTimeout(timeout)

      setIsFiltering(true)
      // Make a new timeout set to go off in 1000ms (1 second)
      timeout = setTimeout(function () {
         setIsFiltering(false)
         setSearch(data)
      }, 1500)

      return data
   }
   //
   const handleSearch = useCallback(
      (_text, _sortOrder, _sortMedium, _medium) => {
         const data = {
            typing: true,
            text: _text,
            order: _sortOrder || 'newer',
            medium: _sortMedium || _medium,
         }
         console.log('++++++++++++++++++ data', _sortOrder, data)

         doSearch(data)
      },
      [search]
   )

   useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search)
      const searchText = urlParams.get('search')
      //console.log('setSearch', searchText)
      doSearch({ ...search, text: searchText })
   }, [])

   const handleClick = () => {
      context.setGoBack(() => {
         return search.text
            ? navigate('/esteban-vicente/artwork/1?search=' + search.text)
            : navigate('/esteban-vicente/artwork/1')
      })

      context.setIsStudio(false)
   }

   const images =
      (!!search.text && search.text.length > 0) ||
      search.order.length > 0 ||
      search.medium.length > 0
         ? edges
              .filter(element => {
                 if (!!search.medium) {
                    return (
                       element.node.relationships.field_category.name.toLowerCase() ===
                       search.medium
                    )
                 }

                 return element.node
              })
              .filter(element => {
                 //console.log(search.text)
                 const _searchText = search.text
                    ? search.text.toLowerCase()
                    : ''

                 // search by fields { title, category, date }
                 const title = element.node.title
                    ? element.node.title.toLowerCase()
                    : ''

                 const code = element.node.field_object_number
                    ? element.node.field_object_number.toLowerCase()
                    : ''

                 const field_medium = element.node.field_medium
                    ? element.node.field_medium.toLowerCase()
                    : ''
                 const category = element.node.relationships.field_category.name
                    ? element.node.relationships.field_category.name.toLowerCase()
                    : ''
                 const date = element.node.field_date
                    ? element.node.field_date.toLowerCase()
                    : ''

                 return (
                    title.indexOf(_searchText) > -1 ||
                    field_medium.indexOf(_searchText) > -1 ||
                    category.indexOf(_searchText) > -1 ||
                    date.indexOf(_searchText) > -1 ||
                    code.indexOf(_searchText) > -1
                 )
              })
              .sort((a, b) => {
                 let dateA = a.node.field_date.match(/\d+/)
                 let dateB = b.node.field_date.match(/\d+/)

                 // DD-MM-YYYY to YYYY-MM-DD
                 dateA = new Date(dateA ? dateA[0] : '0000', '01', '01')
                 dateB = new Date(dateB ? dateB[0] : '0000', '01', '01')

                 if (search.order === 'newer') {
                    return dateB > dateA ? 1 : -1
                 } else if (search.order === 'older') {
                    return dateB < dateA ? 1 : -1
                 } else if (search.order === 'a-z') {
                    return b.node.title < a.node.title ? 1 : -1
                 } else if (search.order === 'z-a') {
                    return b.node.title > a.node.title ? 1 : -1
                 }
                 return a - b
              })
         : currentList

   return (
      <Layout
         title='Artwork'
         className={exhibitionsPageStyles.load}
         secondary
         menuData={data.allMenuItems.nodes[0]}
         showFooter
         colorHeader='#fcf6f3'
         goBack={true}
      >
         <SearchSection
            onChange={handleSearch}
            value={search.text ? search.text : null}
            categories={data.categories.edges}
         />

         <Container>
            <ul className={exhibitionsPageStyles.list}>
               {images.length > 0 && !isFiltering ? (
                  images.map((element, key) => {
                     const image_name = element.node.title
                     return (
                        element?.node?.relationships?.imgMobile[0]
                           ?.localFile && (
                           <li
                              className={exhibitionsPageStyles.element}
                              key={key}
                           >
                              <Link
                                 onClick={handleClick}
                                 to={
                                    element.node.field_object_number
                                       ? '/artwork/' +
                                         element.node.field_object_number
                                       : '/esteban-vicente/artwork/1'
                                 }
                              >
                                 <Image
                                    key={key}
                                    //className={`${roomStyles.roomImg} ${
                                    //currentHover === myImage.src
                                    //? roomStyles.roomHover
                                    //: ''
                                    //}`}
                                    src={
                                       isMobile
                                          ? element.node.relationships
                                               .imgMobile[0].localFile
                                               .childImageSharp.fixed
                                          : element.node.relationships
                                               .imgDescktop[0].localFile
                                               .childImageSharp.fixed
                                    }
                                    title={
                                       image_name +
                                       ' - ' +
                                       element.node.field_date
                                    }
                                    width={isMobile ? 200 : 500}
                                 />
                                 {
                                    //<Img
                                    //fixed={
                                    //isMobile
                                    //? element.node.relationships
                                    //.imgMobile[0].localFile
                                    //.childImageSharp.fixed
                                    //: element.node.relationships
                                    //.imgDescktop[0].localFile
                                    //.childImageSharp.fixed
                                    //}
                                    //className={exhibitionsPageStyles.shadow}
                                    //alt={
                                    //image_name +
                                    //' - ' +
                                    //element.node.field_date
                                    //}
                                    //title={
                                    //image_name +
                                    //' - ' +
                                    //element.node.field_date
                                    //}
                                    //fadeIn
                                    ///>
                                 }
                                 <h5>{image_name}</h5>
                                 <p>
                                    {element.node.field_date},{' '}
                                    {
                                       element.node.relationships.field_category
                                          .name
                                    }
                                 </p>
                              </Link>
                           </li>
                        )
                     )
                  })
               ) : (
                  <h1 className={exhibitionsPageStyles.results}>
                     {context.cursor === 0 ||
                     context.hasMore(pageContext) ||
                     isFiltering
                        ? 'Loading...'
                        : 'No results'}
                  </h1>
               )}
            </ul>
            <View pageContext={pageContext} themeContext={context} />
         </Container>
      </Layout>
   )
}
